import axios from "axios";
import "./toastify";

let form = document.getElementById("js-faqForm");
let formButton = form.querySelector("button");
let formGdpr = document.getElementById("js-gdpr");
let formPhone = document.getElementById("js-phone");

form.addEventListener("submit", function(e) {
    e.preventDefault();
    submitContactForm();
});

const submitContactForm = async () => {
    formButton.disabled = true;
    formButton.classList.add("disabled");

    try {
        let formData = new FormData();

        formData.append("gdpr", formGdpr.value);
        formData.append("phone", formPhone.value);

        const result = await axios.post(`/api/custom-helper/submit`, formData, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-CSRF-TOKEN": document
                    .getElementsByName("csrf-token")[0]
                    .getAttribute("content")
            }
        });

        form.reset();
        showToast("Formulár bol úspešne odoslaný.", "success");
    } catch (e) {
        showToast("Formulár sa nepodarilo odoslať.", "error");
    }

    formButton.disabled = false;
    formButton.classList.remove("disabled");
};
